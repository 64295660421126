:root {
  --primary-color: #c41e3a; /* Dark Red */
  --secondary-color: #000;
}

/* Logo Animation */
.navbar-brand i {
  transition: transform 0.5s;
}
.navbar-brand:hover i {
  transform: rotate(360deg);
}
.logo-main
{
height:50px;
margin-right:10px;
}

/* Navigation Link Hover Effect */
.nav-link {
  position: relative;
  margin: 0 15px;
  transition: color 0.3s;
}

.nav-link-inner::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 2px;
  background: #c41e3a;
  transition: width 0.3s;
}

.nav-link:hover .nav-link-inner::after {
  width: 100%;
}
.navbar
{
  background: rgba(0,0,0,0.9); 
  backdrop-filter: blur(10px);
}

/* Hero Section */
.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

/* Service Cards */
.service-card {
  background: rgba(255,255,255,0.1);
  border-radius: 10px;
  transition: transform 0.3s;
}
.service-card:hover {
  transform: translateY(-10px);
}

/* Animations */
.animate-on-scroll {
  opacity: 0;
  transition: opacity 0.6s, transform 0.6s;
}
.animate-on-scroll.visible {
  opacity: 1;
  transform: translateY(0) !important;
}

/* FAQ Accordion */
.accordion-button:not(.collapsed) {
  background: var(--primary-color);
  color: white;
}
/* Projects Section */
.project-card {
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  background: #111;
}

.project-image {
  position: relative;
  overflow: hidden;
}

.project-image img {
  transition: transform 0.5s;
}

.project-card:hover img {
  transform: scale(1.1);
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(196,30,58,0.8);
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-card:hover .project-overlay {
  opacity: 1;
}

.tech-list {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}


/* About Modal Style */
/* Corporate Modal Styles */
.corporate-modal {
  background: #0a0a0a;
  color: #fff;
  border-radius: 1.5rem;
  border: 1px solid rgba(196,30,58,0.5);
}

.corporate-modal .modal-body {
  padding: 3rem;
}

.corporate-heading {
  color: var(--primary-color);
  font-size: 2.5rem;
  margin-bottom: 2rem;
  font-weight: 700;
}

/* Timeline Section */
.corporate-timeline {
  border-left: 3px solid var(--primary-color);
  padding-left: 2rem;
  position: relative;
}

.timeline-year {
  color: var(--primary-color);
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
}

/* Leadership Section */
.leadership-profile {
  position: relative;
  margin-bottom: 2rem;
}

.founder-image {
  position: relative;
  overflow: hidden;
  border-radius: 1rem;
  margin-bottom: 1.5rem;
}

.founder-image img {
  width: 100%;
  height: auto;
}

.founder-name {
  color: var(--primary-color);
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.founder-title {
  color: #6c757d;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.founder-quote {
  font-size: 0.95rem;
  line-height: 1.6;
  border-left: 3px solid var(--primary-color);
  padding-left: 1rem;
  margin-left: 1rem;
}

/* Milestones and Values */
.corporate-milestones {
  margin-top: 2rem;
}

.milestones-heading {
  color: var(--primary-color);
  margin-bottom: 1.5rem;
}

.milestone-item {
  display: flex;
  align-items: center;
  padding: 1.2rem;
  background: rgba(255,255,255,0.03);
  border-radius: 0.8rem;
  margin-bottom: 1rem;
  transition: transform 0.3s ease;
}

.milestone-item:hover {
  transform: translateX(10px);
}

.milestone-icon {
  color: var(--primary-color);
  font-size: 1.5rem;
  margin-right: 1.2rem;
}

.core-values {
  margin-top: 2rem;
}

.value-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  background: rgba(255,255,255,0.03);
  border-radius: 0.6rem;
  margin-bottom: 1rem;
  transition: background-color 0.3s ease;
}

.value-item:hover {
  background: rgba(196,30,58,0.1);
}

.value-icon {
  color: var(--primary-color);
  margin-right: 1rem;
  font-size: 1.2rem;
}

/* Philosophy Section */
.corporate-philosophy {
  margin-top: 3rem;
  padding: 2rem;
  background: rgba(196,30,58,0.1);
  border-radius: 1rem;
  border: 1px solid rgba(196,30,58,0.3);
}

.philosophy-heading {
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

/* CTA Section */
.modal-cta {
  margin-top: 3rem;
  text-align: center;
}

.modal-cta .btn {
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 0.8rem;
}
/* End of About Us modal style */

/* Custom Services Modal Styles */
.modal-content {
  border-radius: 20px;
  border: 1px solid rgba(196,30,58,0.5);
}

/* Process Steps */
.process-steps {
  border-left: 3px solid var(--primary-color);
  padding-left: 2rem;
  position: relative;
}

.step {
  position: relative;
}

.step-icon {
  position: absolute;
  left: -3.6rem;
  top: 0;
  background: var(--primary-color);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Phase Cards */
.phase-card {
  background: rgba(255,255,255,0.05);
  border-radius: 15px;
  transition: transform 0.3s;
}

.phase-card:hover {
  transform: translateY(-5px);
}

/* Number Circle */
.number-circle {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--primary-color);
  text-align: center;
  margin-right: 15px;
  color: #fff;
  font-weight: 700;
  line-height: 30px;
}

/* Testimonial Card */
.testimonial-card {
  background: rgba(255,255,255,0.05);
  border-radius: 15px;
  border-left: 3px solid var(--primary-color);
  padding: 1.5rem;
}

blockquote {
  position: relative;
  padding-left: 1rem;
  font-style: italic;
  margin-bottom: 1rem;
}

blockquote::before {
  content: '"';
  position: absolute;
  left: -1rem;
  top: -0.5rem;
  font-size: 2rem;
  color: var(--primary-color);
}

/* Progress Bar */
.progress {
  height: 4px;
  background-color: rgba(255,255,255,0.1);
  border-radius: 2px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: var(--primary-color);
}

/* Specific Progress Bar Widths */
.progress-bar.color-grading {
  width: 95%;
}

.progress-bar.visual-effects {
  width: 90%;
}

/* Technology Stack Styles */
.tech-category {
  margin-bottom: 3rem;
}

.tech-category-heading {
  color: var(--primary-color);
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.tech-stack-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 1.5rem;
}

.tech-card {
  background: rgba(255,255,255,0.03);
  border-radius: 1rem;
  padding: 1.5rem;
  text-align: center;
  transition: transform 0.3s ease;
}

.tech-card:hover {
  transform: translateY(-5px);
  background: rgba(196,30,58,0.1);
}

.tech-card i {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.tech-card h5 {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.tech-card p {
  font-size: 0.9rem;
  color: #6c757d;
}

/* Integration Process */
.integration-process {
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 1px solid rgba(196,30,58,0.2);
}

.process-heading {
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.process-steps {
  display: grid;
  gap: 2rem;
}

.step {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.step-number {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--primary-color);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  flex-shrink: 0;
}

.step-content h5 {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.step-content p {
  font-size: 0.9rem;
  color: #6c757d;
  margin-bottom: 0;
}
/* End of services modal */

/* Contact Section */
#message
{
  height: 150px
}

.contact-form .form-control {
  background: rgba(255,255,255,0.1);  
}

.contact-form .form-control:focus {
  background: rgba(255,255,255,0.2);
  box-shadow: 0 0 15px rgba(196,30,58,0.3);
}

.contact-info-card {
  background: #000;
  backdrop-filter: blur(10px);
  color: #fff;
}

/* Footer Styling */
.footer {
  background: #0a0a0a !important;
}

.footer-link {
  text-decoration: none;
  transition: color 0.3s;
}

.footer-link:hover {
  color: var(--primary-color) !important;
}

.social-icons i {
  font-size: 1.5rem;
  transition: transform 0.3s;
}

.social-icons i:hover {
  transform: translateY(-5px);
}

.newsletter .form-control {
  border: 1px solid var(--primary-color);
}